<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div>
      <div class="mb-1">

        <router-link :to="{ name: 'admin-create-mall' }">
          <button class="btn btn-gradient-primary">
            <i class="fa fa-plus" /> {{ $t('addProduct') }}
          </button>
        </router-link>
      </div>
      <div class="card p-2">

        <b-row>
          <!-- <b-col
            md="9"
            lg="9"
            sm="12"
            class="my-1"
          /> -->

          <b-col cols="12">
            <b-form-group
              label-cols-sm="8"
              label-cols="10"
              label-align-sm="right"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <div class="table-container">

              <b-table
                striped
                hover
                responsive
                :per-page="perPage"
                :items="items"
                :fields="fieldsMultiLang"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                show-empty
                class="table-mobile"
              >
                <template #empty>
                  <div class="text-center">
                    <img
                      src="@/assets/images/anan-img/empty/empty.png"
                      alt="empty"
                      width="180px"
                    >
                    <p>No Data</p>
                  </div>
                </template>
                <template #cell(avatar)="data">
                  <b-avatar :src="data.value" />
                </template>
                <template #cell(index)="data">
                  {{ perPage * (currentPage - 1) + (data.index + 1) }}
                </template>
                <template #cell(status)="data">
                  <p v-if="data.item.hide == 0">
                    แสดงสินค้า
                  </p>
                  <p v-else>
                    ซ่อนสินค้า
                  </p>
                </template>
                <template #cell(sales)="data">
                  {{ data.item.sales ? data.item.sales : 0 }}
                </template>
                <template #cell(actions)="data">

                  <i
                    class="fal fa-edit mr-1"
                    @click="Edit(data.item)"
                  />

                  <i
                    class="fal fa-trash-alt"
                    @click="DeleteData(data.item)"
                  />
                </template>
              </b-table>
            </div>
            <div class="demo-spacing-0 d-flex justify-content-end">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                @input="GetData()"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {
  BRow, BCol, BTable, BPagination, BAvatar, BInputGroup, BFormInput, BButton, BFormGroup, BInputGroupAppend,
  // BForm, BModal,
  BOverlay,
  BCard,
  // BIconController,
} from 'bootstrap-vue'
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
// import vSelect from 'vue-select'

export default {
  components: {
    // ValidationProvider,
    // ValidationObserver,
    BRow,
    BCol,
    BTable,
    BPagination,
    BAvatar,
    BInputGroup,
    BFormInput,
    BButton,
    BFormGroup,
    BInputGroupAppend,
    // BForm,
    // BModal,
    BOverlay,
    BCard,
    // BIconController,
    // vSelect,
  },
  data() {
    return {
      employee_id: null,
      name: null,
      surname: null,
      username: null,
      role: null,
      tel: null,
      password: null,
      id: null,

      show: true,
      required,
      perPage: 10,
      pageOptions: [10, 20, 30, 40, 50, 100],
      totalRows: 0,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [],
      // fields: [
      //   { key: 'index', label: 'ลำดับ' },
      //   { key: 'name', label: 'ชื่อสินค้า', thStyle: { width: '35%' } },
      //   {
      //     key: 'code', label: 'รหัสสินค้า', thStyle: { width: '10%' },
      //   },
      //   {
      //     key: 'quantity', label: 'คงคลัง', sortable: true, thStyle: { width: '10%' },
      //   },
      //   {
      //     key: 'sales', label: 'ขายไปแล้ว', sortable: true, thStyle: { width: '10%' },
      //   },
      //   {
      //     key: 'status', label: 'สถานะ', sortable: true, thStyle: { width: '10%' },
      //   },
      //   {
      //     key: 'add_by', label: 'เพิ่มโดย', sortable: true, thStyle: { width: '15%' },
      //   },
      //   {
      //     key: 'edit_by', label: 'แก้ไขโดย', sortable: true, thStyle: { width: '15%' },
      //   },
      //   {
      //     key: 'actions', label: '', thStyle: { width: '5%' },
      //   },
      // ],
      items: [],
      rolelist: [
        { value: 0, label: 'User' },
        { value: 1, label: 'Admin' },
      ],
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
    }
  },
  computed: {
    fieldsMultiLang() {
      return [
        { key: 'index', label: this.$t('sequence') },
        { key: 'name', label: this.$t('productName'), thStyle: { width: '35%' } },
        {
          key: 'code', label: this.$t('productCode'), thStyle: { width: '10%' },
        },
        {
          key: 'quantity', label: this.$t('stock'), sortable: false, thStyle: { width: '10%' },
        },
        {
          key: 'sales', label: this.$t('sold'), sortable: false, thStyle: { width: '10%' },
        },
        {
          key: 'status', label: this.$t('status'), sortable: false, thStyle: { width: '10%' },
        },
        {
          key: 'add_by', label: this.$t('addedBy'), sortable: false, thStyle: { width: '15%' },
        },
        {
          key: 'edit_by', label: this.$t('editedBy'), sortable: false, thStyle: { width: '15%' },
        },
        {
          key: 'actions', label: '', thStyle: { width: '5%' },
        },
      ]
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.GetData()
    this.totalRows = this.items.length
  },
  methods: {
    GetData() {
      // let index = 0
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
      }
      this.$http
        .post('/product/index', { params })
        .then(response => {
          // console.log(response.data)
          this.show = false
          this.onFiltered(response.data)
        })
        .catch(error => console.log(error))
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering

      this.totalRows = filteredItems.total
      this.items = filteredItems.data
      // this.currentPage = 1
    },
    Edit(data) {
      // eslint-disable-next-line no-underscore-dangle
      this.$router.push(`/admin/edit-mall/${data._id}`)
    },
    DeleteData(data) {
      this.$swal({
        title: 'ยืนยันที่จะลบสินค้า ?',
        text: `ชื่อ ${data.name ? data.name : '-'} หรือไม่ ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          const obj = {
            data,
          }
          this.$http
            .post('/employee/delete', obj)
            // eslint-disable-next-line no-unused-vars
            .then(response => {
              this.GetData()
              this.Success('ลบสินค้าสำเร็จ')
            })
            .catch(error => {
              this.SwalError(error.response.data.message)
            })
        } else {
          this.$swal('ยกเลิก', 'ยกเลิกคำสั่ง !', 'error')
        }
      })
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    toggleModal() {
      this.$refs['my-modal'].toggle('#toggle-btn')
    },
  },

}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
@media only screen and (max-width: 600px) {

  .table-mobile {
    width: 1000px !important;
  }
}
</style>
